import { FunctionalComponent, h } from 'preact'; // eslint-disable-line
import { Route, Router } from 'preact-router';

import AboutRoute from '../routes/about-route';
import AppCustomModelRecordRoute from '../routes/app-custom-model-record-route';
import AppHomeRoute from '../routes/app-home-route';
import AppPageRoute from '../routes/app-page-route';
import AppProfileRoute from '../routes/app-profile-route';
import AppUsersRoute from '../routes/app-users-route';
import CreateAppRoute from '../routes/create-app-route';
import CreateCustomModelRoute from '../routes/create-custom-model-route';
import CustomModelRoute from '../routes/custom-model-route';
import HomeRoute from '../routes/home-route';
import NotFoundRoute from '../routes/not-found-route';
import ModelsRoute from '../routes/models-route';
import CustomModelRecordRoute from '../routes/custom-model-record-route';
import CustomModelRecordsRoute from '../routes/custom-model-records-route';
import PageRoute from '../routes/page-route';
import PagesRoute from '../routes/pages-route';
import PrivacyPolicyRoute from '../routes/privacy-policy-route';

const App: FunctionalComponent = () => (
  <div id="app">
    <Router>
      {/* Public Routes */}
      <Route path="/" component={HomeRoute} />
      <Route path="/about" component={AboutRoute} />
      <Route path="/create" component={CreateAppRoute} />
      <Route path="/privacy-policy" component={PrivacyPolicyRoute} />

      {/* Admin Routes */}
      <Route path="/app/:appSlug/admin" component={PagesRoute} />
      <Route path="/app/:appSlug/admin/pages" component={PagesRoute} />
      <Route path="/app/:appSlug/admin/pages/:pageId" component={PageRoute} />
      <Route path="/app/:appSlug/admin/data" component={ModelsRoute} />
      <Route path="/app/:appSlug/admin/data/user" component={AppUsersRoute} />
      <Route path="/app/:appSlug/admin/data/:customModelSlug" component={CustomModelRecordsRoute} />
      <Route path="/app/:appSlug/admin/data/:customModelSlug/configure" component={CustomModelRoute} />
      <Route path="/app/:appSlug/admin/data/:customModelSlug/:customModelRecordId" component={CustomModelRecordRoute} />
      <Route path="/app/:appSlug/admin/new-category" component={CreateCustomModelRoute} />

      {/* App Routes */}
      <Route path="/app/:appSlug" component={AppHomeRoute} />
      <Route path="/app/:appSlug/profile" component={AppProfileRoute} />
      <Route path="/app/:appSlug/:pageId" component={AppPageRoute} />
      <Route path="/app/:appSlug/:pageId/:customModelSlug/:customModelRecordId" component={AppCustomModelRecordRoute} />

      <NotFoundRoute default />
    </Router>
  </div>
);

export default App;
